import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://app.zcore.network/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },   
    ],
  },    
  /*
  {
    label: 'Trade LPv1',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange (v1)',
        href: 'https://swap.zcore.network/#/swap',
      },
      {
        label: 'Liquidity (v1)',
        href: 'https://swap.zcore.network/#/pool',
      },   
    ],
  },  
  {
    label: 'Trade LPv2',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange (v2)',
        href: 'https://exchange.pancakeswap.finance/#/swap',
      },
      {
        label: 'Liquidity (v2)',
        href: 'https://exchange.pancakeswap.finance/#/pool',
      },      
    ],
  },  
  
  {
    label: 'Exchange',
    icon: 'ExchangeIcon',
    href: 'https://exchange.zcore.network/#/swap',
  },
  {
    label: 'Liquidity',
    icon: 'LiquidityIcon',
    href: 'https://exchange.zcore.network/#/pool',
  },
*/      
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://app.zcore.network/farms',
  },  

  /*
  {
    label: 'Barn',
    icon: 'BarnIcon',
    href: 'https://app.zcore.network/barn',
  },
  */
  {
    label: 'Vaults',
    icon: 'VaultsIcon',
    items: [
      {
        label: 'Old',
        href: 'https://vaults.zcore.network/zefi',
      },
      {
        label: 'BSC (new)',
        href: 'https://vaults.zcore.farm/',
      },   
      {
        label: 'Polygon (new)',
        href: 'https://polygon.zcore.farm/',
      },         
    ],
  },  
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://app.zcore.network/pools',
  },        
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://zefi.zcore.network/lottery',
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: 'https://app.zcore.network/ifo',
  },    
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },

  {
    label: 'Buy ZEFI',
    icon: 'MoreIcon',
    items: [
      {
        label: 'ZCore Dex',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: '1inch',
        href: 'https://app.1inch.io/#/56/swap/BNB/ZEFI',
      },      
      {
        label: 'Pancake Swap',
        href: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: 'Cointiger',
        href: 'https://www.cointiger.com/en-us/#/trade_center?coin=zefi_usdt',
      },
    ],
  },

  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Price Chart',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/zcore-finance/',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/defi/zcore-finance',
      },
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },      
      {
        label: 'Github',
        href: 'https://github.com/zcore-coin/',
      },
      {
        label: 'Portfolio Watcher',
        href: 'https://pacoca.io/',
      },
      {
        label: 'Vfat Tools',
        href: 'https://vfat.tools/bsc/zcore/',
      },
      {
        label: 'Add to Metamask',
        href: 'https://bit.ly/3sSDdrB',
      },      
    ],
  },
  /*  
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Buy ZeFi',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: 'Buy wZCR',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x1f01dc57c66c2f87d8eab9c625d335e9defe6912',
      },
      {
        label: 'Buy ZCRT',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x31184efef1ccd72696facd35594af3af2a537a3f',
      },
    ],
  },
  */
  {
    label: 'ZCore Network',
    icon: 'ZCoreIcon',
    href: 'https://zcore.network',
  },
  {
    label: 'Credit Card',
    icon: 'CCIcon',
    href: 'https://finance.zcore.network',
  },  
  {
    label: 'Audit By Certik',
    icon: 'AuditIcon',
    href: 'https://certik.org/projects/zcore',
  },
  {
    label: 'D-U-N-S Number',
    icon: 'AuditIcon',
    href: 'https://profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3141951&PaArea=1',
  },  
  {
    label: 'Docs',
    icon: 'GitbookIcon',
    href: 'https://docs.zcore.fi/',
  },
]

export default config
