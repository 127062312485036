import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap-libs/sdk'

export const ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const ZEFI = new Token(ChainId.MAINNET, '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07', 18, 'ZEFI', 'ZCore Finance')
// export const WLQX = new Token(ChainId.MAINNET, '0x4495e673eE53c61CE79c35D5e299733639362aEc', 18, 'WLQX', 'Wrapped LQX')
// export const POLIS = new Token(ChainId.MAINNET, '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd', 18, 'POLIS', 'Polis')
// export const WBTCT = new Token(ChainId.MAINNET, '0xd47Ba9A00EB87B9E753c6651e402DAD7D9f1C4Ca', 18, 'WBTCT', 'Wrapped BitcoinToken')
// export const WZYON = new Token(ChainId.MAINNET, '0xa5594a2caf790c8eeb9037ab18d0da305bbdc8b6', 18, 'WZYON', 'Wrapped BitZyon')
// export const NULS = new Token(ChainId.MAINNET, '0x8cd6e29d3686d24d3c2018cee54621ea0f89313b', 18, 'NULS', 'Nuls')

export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c', 18, 'BTCB', 'Binance-Peg Bitcoin Token')
// export const CBRL = new Token(ChainId.MAINNET, '0x9e691fd624410d631c082202b050694233031cb7', 18, 'CBRL', 'Crypto BRL')
export const BRZ = new Token(ChainId.MAINNET, '0x71be881e9c5d4465b3fff61e89c6f3651e69b5bb', 18, 'BRZ', 'BRZ Token')

// export const GOAL = new Token(ChainId.MAINNET, '0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F', 18, 'GOAL', 'Goal Token')
// export const WKSOC = new Token(ChainId.MAINNET, '0xdeE6Ae8455be6470c9dD5865df191414aF9C287E', 18, 'WKSOC', 'Wrapped KickSoccer')
// export const WIQ = new Token(ChainId.MAINNET, '0x8f1b7df4a34c8dd072cf6408be9c778cfabdf8d0', 18, 'WIQ', 'Wrapped IQCash')

export const ZBO = new Token(ChainId.MAINNET, '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8', 18, 'ZBO', 'ZBoost')
export const ZCR = new Token(ChainId.MAINNET, '0x2E40565771cf2BF8622C41CDd85701519F22418A', 18, 'ZCR', 'ZCore')


export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token'
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, UST, ETH, BTCB, ZBO, ZEFI, BRZ, ZCR],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, UST, ETH, ZEFI, BTCB, ZBO, BRZ, ZCR],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, UST, ETH, ZEFI, BTCB, ZBO, BRZ, ZCR],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07', 18, 'ZEFI', 'ZCore Finance'),
      new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [BUSD, USDT],
    [ZEFI, USDT],
    [ZEFI, BTCB],
    [ZEFI, ETH],
    [ZEFI, BRZ],
    [ZEFI, ZBO],
    [
      ZBO, 
      new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [
      ZCR, 
      new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],    
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 200
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
