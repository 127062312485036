import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js/bignumber'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */

// const api = 'https://finance.zcore.network/price' 
// const api = 'https://api.pancakeswap.com/api/v1/price'

export const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          const {cakeAddress, busdAddress, lpAddress} = priceContracts;
          const calls = [
            [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
            [busdAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [cakeAmount, busdAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const cake = new BigNumber(cakeAmount);
          const busd = new BigNumber(busdAmount);
          const cakePrice = busd.div(cake).toNumber();
          setData(cakePrice)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}


export const usePriceBbnbBusd = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){


          const busdAddress  = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
          const bnbAddress  = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
          const lpAddress    = '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16';
          const calls = [
            [busdAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
            [bnbAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [busdAmount, bnbAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const busd = new BigNumber(busdAmount);
          const bnb = new BigNumber(bnbAmount);
          const BusBnbPrice = busd.div(bnb).toNumber();

          setData(BusBnbPrice)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export const usePriceBoost = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){



          // const BnbBusdPrice = usePriceBbnbBusd();
          // const {cakeAddress, busdAddress, lpAddress} = priceContracts;
          const cakeAddress  = '0x7d3550d0b0ac3590834ca6103907cd6dd41318f8';
          const bnbAddress  = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
          const lpAddress    = '0x0418de230ed174d99e80ce6cbc398cc97cd31756';
          const calls = [
            [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
            [bnbAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [cakeAmount, bnbAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const cake = new BigNumber(cakeAmount);
          const bnb = new BigNumber(bnbAmount);
          const cakeBnbPrice = bnb.div(cake).toNumber();
          // const cakeBusdPrice = (new BigNumber(cakeBnbPrice)).div(BnbBusdPrice).toNumber();


          setData(cakeBnbPrice)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}


export const usePriceZefi = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){



          // const BnbBusdPrice = usePriceBbnbBusd();
          // const {cakeAddress, busdAddress, lpAddress} = priceContracts;
          const cakeAddress  = '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07';
          const bnbAddress  = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
          const lpAddress    = '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc';
          const calls = [
            [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
            [bnbAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [cakeAmount, bnbAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const cake = new BigNumber(cakeAmount);
          const bnb = new BigNumber(bnbAmount);
          const cakeBnbPrice = bnb.div(cake).toNumber();
          // const cakeBusdPrice = (new BigNumber(cakeBnbPrice)).div(BnbBusdPrice).toNumber();


          setData(cakeBnbPrice)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export default useGetPriceData